$(window).on('load', page_loaded);

function page_loaded() {
    // frappe.router.on('change', () => {
    //     route = frappe.get_route()
    //     if(route.length == 2 && !frappe.user_roles.includes("System Manager")){
    //         if (route[0] == "Workspaces"){
    //             $("#page-Workspaces .page-actions").html("")
    //         }
    //     }
    // })
}

// frappe.router.on('change', () => {
//     route = frappe.get_route()
//     if(route.length == 2 && !frappe.user_roles.includes("System Manager")){
//         if (route[0] == "Workspaces"){
//             $("#page-Workspaces .page-actions").html("")
//         }
//     }
// })
